.block {
    border: none;
    border-radius: 3px;
    border-color: rgb(17, 16, 16);
    background: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px 0px;
    height: 100%;
  }

.small-block {
  border: none;
  border-radius: 3px;
  border-color: rgb(17, 16, 16);
  background: white;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px 0px;
}
  
  svg.MuiSvgIcon-root {
    font-size: 2rem;
  }

  @media (min-width: 768px) {
    .root-flex {
      display: flex;
      justify-content: space-between;
    }
  }
